import { NgModule } from '@angular/core';
import { ROUTES, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guards/auth.guard';
import {
  XgsGuardGuard,
  CanDeactivateGuard
} from './auth/auth-guards/xgs-guard.guard';
import { AuthorizeComponent } from './authorize/authorize.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { SupersetComponent } from './superset/superset.component';
import { environment } from '@env/environment';

const routes: Routes = [
  {
    path: 'landing',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: [XgsGuardGuard]
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'home', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'authorize', component: AuthorizeComponent, pathMatch: 'full' },
  {
    path: 'user',
    canLoad: [XgsGuardGuard],
    loadChildren: async () => {
      return import('./user-management/user/user.module').then(m => {
        return m.UserModule;
      });
    }
  },
  {
    path: 'role',
    canLoad: [XgsGuardGuard],
    loadChildren: async () => {
      return import('./user-management/role/role.module').then(m => {
        return m.RoleModule;
      });
    }
  },

  {
    path: 'iperl',
    canLoad: [XgsGuardGuard],
    loadChildren: async () => {
      return import('./iperldashboard/iperl.module').then(m => {
        return m.IperlModule;
      });
    }
  },
  {
    path: 'pipediver-dashboard',
    canLoad: [XgsGuardGuard],
    loadChildren: async () => {
      return import('./pipediver-dashboard/pipediver-dashboard.module').then(
        m => {
          return m.PipediverDashboardModule;
        }
      );
    }
  },

  {
    path: 'superset',
    component: SupersetComponent,
    pathMatch: 'full',
    canActivate: [XgsGuardGuard, AuthGuard]
  },
  {
    path: 'pipeline-data-table',
    canLoad: [XgsGuardGuard],
    loadChildren: async () => {
      return import('./pipeline-data-table/pipeline-data-table.module').then(
        m => {
          return m.PipelineDataTableModule;
        }
      );
    }
  },

  {
    path: 'gsodatawarehouse',
    canLoad: [XgsGuardGuard],
    loadChildren: async () => {
      return import('./GSO-Datawarehouse/gso-datawarehouse.module').then(m => {
        return m.GSODatawarehouseModule;
      });
    }
  },
  {
    path: 'scheduler',
    canLoad: [XgsGuardGuard],
    canDeactivate: [CanDeactivateGuard],
    loadChildren: async () => {
      return import('./scheduler/scheduler.module').then(m => {
        return m.SchedulerModule;
      });
    }
  },
  {
    path: '**',
    redirectTo: 'landing'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      enableTracing: false,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      useFactory: () => {
        const finalRoutes: Routes = [];

        return [...finalRoutes, ...routes];
      },
      multi: true
    }
  ]
})
export class AppRoutingModule {}
