import { LogLevel } from 'angular-auth-oidc-client';

/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: false,
  localhostRedirect: false,
  systemInfoUrl: '',
  appName: 'Xylem Sprout',
  apiBaseUrl: '/gravity-angular-ui/svc/',
  request: {
    headers: {
      'Content-Type': 'application/json',
      'APP-ID': 'gravity-angular-ui'
    },
    withCredentials: true
  },
  xgsClientID: '633f67e8-79ad-4488-9de0-3338fcc2ee7c', // qa cluster
  xgsBaseURL: 'https://cloudfront-qa.xylem-cloud.com/xgs',
  xgsIdp: 'cloudfront-qa.xylem-cloud.com/xgs/auth/realms/xgsum',
  logLevel: LogLevel.Debug,
  xylemDataLakeGUI: '089548892904-us-east-1-xylem-data-lake-gui',
  identityPoolId: 'us-east-1:83281e5d-fa0d-4946-b492-3de2c9b7949a',
  GUI_IPerlDashboard:
    'https://1jb6cw17h4.execute-api.us-east-1.amazonaws.com/v1',
  GUI_DT96Dashboard:
    'https://m0g1fb02o9.execute-api.us-east-1.amazonaws.com/v1',
  GUI_DataEntryTool:
    'https://z232kta0q4.execute-api.us-east-1.amazonaws.com/v1',
  KeplerDashboard: 'https://he8juoc2uf.execute-api.us-east-1.amazonaws.com/v1',
  GUI_Lifecycleservices:
    'https://272w29fgc6.execute-api.us-east-1.amazonaws.com/v1',
  repair_economy: 'https://4bqbtawmk5.execute-api.us-east-1.amazonaws.com/v1',
  environmentName: 'dev',
  domain: 'dev.xylemsprout.com',
  supersetUrl: 'https://superset.dev.xylemsprout.com/sync',
  pipeDiverDashboard:
    'https://dashboards.dev.xylemsprout.com/pipediver-dashboard',
  pipelineData: 'https://ilhy4oldih.execute-api.us-east-1.amazonaws.com/v1',
  GSO_RateCard: 'https://api.dev.xylemsprout.com/v_center',
  schedulerApi: 'https://api.dev.xylemsprout.com/xsprout-scheduler',
  schedulerResultsBucket: '089548892904-us-east-1-xylem-data-lake-gui',
  jiraApi: 'https://api.dev.xylemsprout.com/jira',
  gsoDWFinOps: 'https://api.dev.xylemsprout.com/fin-ops',
  resyncJiraQueue: 'https://sqs.us-east-1.amazonaws.com/089548892904/finops-queue',

  /* Superset URLs for GSO Data Warehouse  */

  vCenterDashboard:
    'https://superset.dev.xylemsprout.com/superset/dashboard/gso_vcenter/?standalone=true',
  jiraDashboard:
    'https://superset.dev.xylemsprout.com/superset/dashboard/gso_jira/?standalone=true',
  netAppDashboard:
    'https://superset.dev.xylemsprout.com/superset/dashboard/gso_netapp/?standalone=true',
  paloAltoDashboard: '',
  soalrWindsDashboard:
    'https://superset.dev.xylemsprout.com/superset/dashboard/gso_solarwinds/?standalone=true',
  cloudHealthDashboard: '',
  mongoDBAtlasDashboard: '',
  finOpsDashboard:
    'https://superset.dev.xylemsprout.com/superset/dashboard/finOps/?standalone=true'
};
