import { Injectable } from '@angular/core';
import { Amplify } from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { Cache } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Storage } from '@aws-amplify/storage';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class AmplifyService {
  federatedSignInConfig;
  userObj;

  constructor(public oidcSecurityService: OidcSecurityService) {
    // configure amplify
    Amplify.configure({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: environment.identityPoolId,

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // REQUIRED
        identityPoolRegion: 'us-east-1'
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      API: {
        endpoints: [
          {
            name: 'GUI_IPerlDashboard',
            endpoint: environment.GUI_IPerlDashboard
          },
          {
            name: 'GUI_DT96Dashboard',
            endpoint: environment.GUI_DT96Dashboard
          },
          {
            name: 'GUI_DataEntryTool',
            endpoint: environment.GUI_DataEntryTool
          },
          {
            name: 'GUI_Kepler',
            endpoint: environment.KeplerDashboard
          },
          {
            name: 'GUI_Lifecycleservices',
            endpoint: environment.GUI_Lifecycleservices
          },
          {
            name: 'repair_economy',
            endpoint: environment.repair_economy
          },
          {
            name: 'pipelineData',
            endpoint: environment.pipelineData
          },
          {
            name: 'GSO_RateCard',
            endpoint: environment.GSO_RateCard
          },
          {
            name: 'xsprout-scheduler',
            endpoint: environment.schedulerApi
          },
          {
            name: 'jira',
            endpoint: environment.jiraApi
          },
          {
            name: 'FinOps',
            endpoint: environment.gsoDWFinOps
          },
          {
            name: 'DisasterRecovery',
            endpoint: environment.disasterRecoveryApi
          }
        ]
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Storage: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        AWSS3: {
          bucket: environment.xylemDataLakeGUI,
          region: 'us-east-1',
          customPrefix: {
            public: ''
          }
        }
      }
    });

    this.federatedSignInConfig = {
      identityProviderName: environment.xgsIdp,
      expiresIn: 3600,
      user: this.userObj
    };
  }

  async callAPI(
    httpMethod: string,
    apiName: string,
    path: string,
    init?: object
  ): Promise<any> {
    let response = {};

    // renew amplify tokens first if needed
    await this.renewAmplifyTokens();

    try {
      switch (httpMethod) {
        case 'get':
          response = await API.get(apiName, path, init);
          break;

        case 'head':
          response = await API.head(apiName, path, init);
          break;

        case 'patch':
          response = await API.patch(apiName, path, init);
          break;

        case 'post':
          response = await API.post(apiName, path, init);
          break;

        case 'put':
          response = await API.put(apiName, path, init);
          break;

        case 'delete':
          response = await API.del(apiName, path, init);
          break;
        default:
        // do nothing for now
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: ${error}`);
    }

    return response;
  }

  async getFileFromS3(key: string, bucket?: string): Promise<any> {
    // renew amplify tokens first if needed
    await this.renewAmplifyTokens();

    let data = {};

    try {
      data = await Storage.get(key, {
        download: true,
        bucket: bucket ? bucket : environment.xylemDataLakeGUI
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: ${error}`);
    }

    return data;
  }

  async listFileFromS3(key: string): Promise<any> {
    // renew amplify tokens first if needed
    await this.renewAmplifyTokens();

    let data = {};

    try {
      data = await Storage.list(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: ${error}`);
    }

    return data;
  }

  async signIn(): Promise<void> {
    try {
      const token = this.oidcSecurityService.getAccessToken();
      await Auth.federatedSignIn(
        this.federatedSignInConfig.identityProviderName,
        {
          token,
          // eslint-disable-next-line camelcase,@typescript-eslint/naming-convention
          expires_at:
            this.federatedSignInConfig.expiresIn * 1000 + new Date().getTime()
        },
        this.userObj
      ).then(value => {
        Cache.setItem('federatedInfo', value, {
          expires: jwt_decode(token)['exp'] * 1000
        });
      });
    } catch (e) {
      console.error(`error: ${e}`);
    }
  }

  async renewAmplifyTokens(): Promise<void> {
    if (Cache.getItem('federatedInfo') !== null) {
      const token = this.oidcSecurityService.getAccessToken();
      if (new Date().getTime() >= jwt_decode(token)['exp'] * 1000) {
        await this.signIn();
      }
    } else {
      await this.signIn();
    }
  }
}
